body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  line-height: 1.5;
  color: #24292e;
  background-color: #fff;
}

.app {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

input.error {
  border-color: red;
}

.input-feedback {
  color: red;
  height: 5px;
  margin-top: -12px;
}
.no-property-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  color: #777;
  margin: 0 auto;
}
.text-upper {
  text-transform: uppercase;
}

.no-property-icon {
  font-size: 48px;
  margin-bottom: 10px;
}

.no-property-text {
  font-size: 20px;
  font-weight: bold;
}
.topBarWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.topBarWrapper select {
  margin-left: 10px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 3px #ddd;
}
.topBarWrapper select:focus {
  outline: none;
}
.languageForm.desktop {
  display: flex;
  justify-content: flex-end;
  margin: 12px;
}
.languageForm.desktop select {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 3px #ddd;
}
.languageForm.desktop select:focus {
  outline: none;
}

.react-datepicker__triangle {
  display: none;
}

.appatTextWrap {
  display: flex;
  flex-direction: column;
  min-height: 335px;
  justify-content: space-between;
}
.priceBtnWrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.appartImageHolder {
  height: 274px;
}
