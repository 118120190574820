:root {
  --primary-color: #6562ac;
  --primary-hover-color: #716fa6;
  --secondary-color: #f9f8ff;
  --secondary-hover-color: #e8e8ea;
}

html {
  font-family: sans-serif; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/**
 * Remove default margin.
 */

body {
  margin: 0;
}

/* HTML5 display definitions
	 ========================================================================== */

/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

/**
 * 1. Correct `inline-block` display not defined in IE 8/9.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */

audio,
canvas,
progress,
video {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */

[hidden],
template {
  display: none;
}

/* Links
	 ========================================================================== */

/**
 * Remove the gray background color from active links in IE 10.
 */

a {
  background-color: transparent;
  transition: all 0.35s ease;
}

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */

a:active,
a:hover {
  outline: 0;
}

/* Text-level semantics
	 ========================================================================== */

/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */

abbr[title] {
  border-bottom: 1px dotted;
}

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */

b,
strong {
  font-weight: bold;
}

/**
 * Address styling not present in Safari and Chrome.
 */

dfn {
  font-style: italic;
}

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/**
 * Address styling not present in IE 8/9.
 */

mark {
  background: #ff0;
  color: #000;
}

/**
 * Address inconsistent and variable font size in all browsers.
 */

small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* Embedded content
	 ========================================================================== */

/**
 * Remove border when inside `a` element in IE 8/9/10.
 */

img {
  border: 0;
}

/**
 * Correct overflow not hidden in IE 9/10/11.
 */

svg:not(:root) {
  overflow: hidden;
}

/* Grouping content
	 ========================================================================== */

/**
 * Address margin not present in IE 8/9 and Safari.
 */

figure {
  margin: 1em 40px;
}

/**
 * Address differences between Firefox and other browsers.
 */

hr {
  box-sizing: content-box;
  height: 0;
}

/**
 * Contain overflow in all browsers.
 */

pre {
  overflow: auto;
}

/**
 * Address odd `em`-unit font size rendering in all browsers.
 */

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

/* Forms
	 ========================================================================== */

/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */

/**
 * 1. Correct color not being inherited.
 *    Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 */

button,
input,
optgroup,
select,
textarea {
  color: inherit; /* 1 */
  font: inherit; /* 2 */
  margin: 0; /* 3 */
}

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */

button {
  overflow: visible;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */

button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 */

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * Remove inner padding and border in Firefox 4+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

input {
  line-height: normal;
}

/**
 * It's recommended that you don't attempt to style these elements.
 * Firefox's implementation doesn't respect box-sizing, padding, or width.
 *
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 */

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */

input[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  box-sizing: content-box; /* 2 */
}

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 */

legend {
  border: 0; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */

textarea {
  overflow: auto;
}

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */

optgroup {
  font-weight: bold;
}

/* Tables
	 ========================================================================== */

/**
 * Remove most spacing between table cells.
 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

.listUnstyled {
  margin: 0;
  padding: 0;
  list-style: none;
}

.clearfix:after,
.container:after {
  content: "";
  display: block;
  clear: both;
}

.dBlock {
  display: block;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  max-height: 1000000px;
}

a {
  text-decoration: none;
}

#pageWrapper {
  position: relative;
  width: 100%;
  /* overflow: hidden; */
}

body {
  background-color: #f6f6f6;
  font-family: "Poppins", sans-serif;
  color: #6e737a;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.container {
  max-width: 1370px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

.colsHolder {
  display: flex;
  flex-wrap: wrap;
  margin-left: -7px;
  margin-right: -7px;
}

.colsHolder > .chCol {
  padding-left: 7px;
  padding-right: 7px;
}

.formPageMainSec,
.reservationSection {
  padding-top: 168px;
  padding-bottom: 296px;
}

.tpSearchBar {
  background-color: #fff;
  padding: 32px 18px;
  border-radius: 24px;
  margin-bottom: 32px;
}

.tpSearchBar .chCol2 {
  width: 100%;
}

.formGroup.error .formControl {
  border-color: #fa452e;
}

.formGroup small.error-text {
  text-align: right;
  font-weight: 500;
  font-size: 14px;
  color: #fa452e;
  display: block;
  width: 100%;
}

.formGroup .formControl {
  width: 100%;
  background-color: #f6f6f6;
  border: 1px solid #f6f6f6;
  padding: 16px 24px;
  border-radius: 16px;
  font-weight: 500;
  color: #212935;
  font-size: 14px;
}

.formGroup .formControl[type="text"]:focus,
.formGroup textarea.formControl:focus {
  outline: none;
}

.formGroup .formControl[type="text"]::placeholder,
.formGroup textarea.formControl::placeholder {
  color: #6e737a;
  opacity: 1;
}

.formGroup .formControl[type="text"]:-ms-input-placeholder,
.formGroup textarea.formControl:-ms-input-placeholder {
  color: #6e737a;
}

.formGroup .formControl[type="text"]::-ms-input-placeholder,
.formGroup textarea.formControl::-ms-input-placeholder {
  color: #6e737a;
}

.searchBtn {
  font-size: 14px;
  line-height: 32px;
  font-weight: 500;
  padding: 12px 24px;
  color: #6e737a;
  background-color: #f6f6f6;
  border-radius: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btnPrimary {
  font-size: 16px;
  line-height: 25px;
  padding: 14px 24px;
  color: #fff;
  font-weight: 500;
  background-color: var(--primary-color);
  border-radius: 8px;
  display: inline-block;
  min-width: 105px;
  transition: background-color 0.35s ease, color 0.35s ease;
}

.btnPrimary:hover {
  background-color: #f6f6f6;
  color: var(--primary-color);
}
.customize-tooltip .btnPrimary {
  font-size: 16px;
  line-height: 25px;
  padding: 14px 24px;
  color: #fff;
  font-weight: 500;
  background-color: var();
  border-radius: 8px;
  display: inline-block;
  min-width: 105px;
  transition: background-color 0.35s ease, color 0.35s ease;
}
.customize-tooltip .btnPrimary:hover {
  background-color: #f6f6f6;
  color: #6562ac;
}

.tpSearchBar .formGroup,
.tpSearchBar .searchBarBtnWrap,
.tpSearchBar .submitBtn {
  margin-bottom: 15px;
}

.selectTabList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 16px;
}

.selectTabList li {
  margin-right: 16px;
  margin-bottom: 8px;
}

.appartCountWrap {
  background-color: #212935;
  color: #fff;
  max-width: 248px;
  width: 100%;
  border-radius: 32px;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  /*margin-bottom: 24px;*/
}

.appartCountWrap .homeIcn {
  margin-right: 11px;
  display: block;
  height: 17px;
  width: 22px;
}

.appartCountWrap strong {
  font-weight: 500;
  display: block;
  margin-right: 10px;
}

.appartCountWrap .closeButton {
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  color: #6e737a;
  border: 2px solid #6e737a;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px !important;
  min-width: 24px !important;
}

.appartCountWrap .closeButton:hover {
  color: #fff;
  border-color: #fff;
}

.catAppListWrap {
  overflow-x: auto;
}

.catAppartList {
  display: flex;
  flex-wrap: wrap;
  min-width: 750px;
}

.catAppartList > li {
  margin-right: 16px;
}

.catIndiWrap strong {
  font-weight: 500;
  color: #474d57;
}

.catIndiWrap {
  background-color: #eaebec;
  border-radius: 32px;
  padding: 12px 24px;
  display: flex;
  align-items: center;
}

.catIndiWrap .homeIcn {
  display: block;
  color: #474d57;
  margin-right: 11px;
  width: 21px;
  height: 19px;
}

.tabIcnList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 16px;
  padding: 8px;
  max-width: 108px;
  width: 100%;
}

.tabIcnList > li .tabs {
  cursor: pointer;
  position: relative;
  padding: 10px 12px;
  border-radius: 8px;
}
.listingView {
}

.tabIcnList > li .tabs.listingView {
  display: inline-block;
}

.tabIcnList > li .tabs:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  padding: 10px 12px;
  border-radius: 8px;
  z-index: 0;
  width: 100%;
  height: 100%;
  transition: all 0.35s linear;
}

.tabIcnList > li .tabs:hover:before {
  background-color: #fbfbfb;
}

.tabIcnList > li .tabs.listingView:before {
  background-color: #f6f6f6;
}

.formPageMainSec.mapStylesBlocks .tabIcnList > li .tabs.listingView::before {
  background-color: transparent;
}

.formPageMainSec.mapStylesBlocks .tabIcnList > li .tabs.mapIcn:before {
  background-color: #f6f6f6;
}

.tabIcnList > li > .tabs .homeIcn {
  display: block;
  height: 19px;
  transition: all 0.35s ease;
}

.tabIcnList > li > .tabs span {
  position: relative;
  z-index: 1;
}

.catListWrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 2px solid #eaebec;
  padding-bottom: 32px;
  margin-bottom: 48px;
}

.catAppartList > li {
  margin-bottom: 20px;
}

.appartmentListWrap .colsHolder {
  margin-left: -16px;
  margin-right: -16px;
}

.appartmentListWrap .chCol {
  padding-left: 16px;
  padding-right: 16px;
}

.blocksStructMain .chCol4 {
  width: 100%;
}

.appartmentListWrap {
  background-color: #fff;
  border-radius: 24px;
  padding: 32px 16px;
  display: block;
  justify-content: center;
  align-items: center;
  min-height: 40vh;
}

.appartInfoWrap {
  background-color: #f6f6f6;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 32px;
}

.appartInfoWrap:hover .appartImageHolder img {
  transform: scale(1.05);
}

.appartInfoWrap .appartImageHolder a {
  height: 100%;
}

.appartInfoWrap .appartImageHolder {
  overflow: hidden;
}

.appartInfoWrap .appartImageHolder img {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  transition: transform 0.35s ease;
}

.appatTextWrap {
  padding: 24px;
  font-size: 14px;
}

.appatTextWrap h2,
.appatTextWrap .h2 {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 17px;
  font-weight: 500;
  line-height: 32px;
  color: #212935;
}
.customize-tooltip h2,
.customize-tooltip .h2 {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 17px;
  font-weight: 500;
  line-height: 32px;
  color: #212935;
}

.customize-tooltip p,
.appatTextWrap p {
  margin-bottom: 24px;
}
.customize-tooltip strong,
.appatTextWrap strong {
  display: block;
  font-size: 15px;
  font-weight: 500;
  color: #212935;
  margin-bottom: 17px;
}

.featureIcnList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -10px;
  margin-right: -10px;
}

.featureIcnList > li {
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 14px;
}

.priceBtnWrap {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  align-items: center;
  /* justify-content: space-around; */
}

.priceBtnWrap .priceTag {
  font-size: 12px;
  display: block;
  /*margin-bottom: 15px;*/
}

.priceBtnWrap .priceTag b {
  color: #212935;
  font-size: 16px;
  font-weight: 600;
}
.customize-tooltip .priceBtnWrap .priceTag b {
  color: #212935;
  font-size: 16px;
  font-weight: 600;
}

.customize-tooltip .priceBtnWrap .btnPrimary:hover {
  background-color: #fff;
}

.priceBtnWrap .btnPrimary:hover {
  background-color: #fff;
}

.pagiNation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
}

.pagiNation > li > .prevIcn,
.pagiNation > li > .NextIcn {
  padding: 20px 22px;
  border-radius: 8px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagiNation > li > .prevIcn {
  background-color: #f6f6f6;
}

.pagiNation > li > .NextIcn {
  background-color: var(--primary-color);
  border: none;
}

.pagiNation > li + li {
  margin-left: 14px;
}

.pagiNation > li > a {
  font-weight: 500;
  color: #9a9a9a;
}

.pagiNation > li > a:hover {
  color: #212935;
}

.pagiNation > li.active > a {
  background-color: #212935;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  color: #fff;
}

.appartBookingDetailWrap .colsHolder {
  margin-left: -40px;
  margin-right: -40px;
}

.appartBookingDetailWrap .chCol {
  padding-left: 40px;
  padding-right: 40px;
}

.appartBookingDetailWrap .chCol8 {
  width: 100%;
}

.appartBookingDetailWrap .chCol4 {
  width: 100%;
}

.appartBookingDetailWrap {
  background-color: #fff;
  padding: 45px 20px;
  border-radius: 24px;
}

.headingHead h2,
.headingHead .h2 {
  font-size: 22px;
  line-height: 40px;
  color: #212935;
  font-weight: 600;
}

.headingHead {
  margin-bottom: 40px;
}

.invalid-input {
  border-color: red;
}

.message {
  color: green;
}
.message.invalid-input {
  color: red;
}
.bookingForm .colsHolder {
  margin-left: -15px;
  margin-right: -15px;
}

.bookingForm .chCol {
  padding-left: 15px;
  padding-right: 15px;
}

.bookingForm .chCol6 {
  width: 100%;
}

.bookingForm .chCol12 {
  width: 100%;
}

.formGroup textarea.formControl {
  resize: none;
  height: 160px;
}

.bookingForm .formGroup {
  margin-bottom: 24px;
}

.bookingForm {
  margin-bottom: 40px;
}

.bookingForm input[type="text"]::placeholder,
.bookingForm textarea.formControl::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6e737a;
}

.bookingForm input[type="text"]::-webkit-input-placeholder,
.bookingForm textarea.formControl::-webkit-input-placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6e737a;
}

.bookingForm input[type="text"]:-ms-input-placeholder,
.bookingForm textarea.formControl:-ms-input-placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6e737a;
}

.bookingForm .formGroup .formControl {
  color: #000;
  font-size: 16px;
}

.bookingForm .formGroup textarea.formControl {
  min-height: 160px;
}

.mbZero {
  margin-bottom: 0 !important;
}

.termConditionWrapper {
  padding-top: 12px;
}

.roomAcceriesWrap {
  border-top: 1px solid #eaebec;
  padding-top: 40px;
}

.asessDescrWrap .textWrap h3,
.asessDescrWrap .textWrap .h3 {
  font-size: 18px;
  color: #212935;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 4px;
}

.asessDescrWrap input {
  width: 128px;
  padding: 8px 40px;
  background-color: #eaebec;
  border-radius: 8px;
  border: 0;
  text-align: center;
  color: #212935;
}

.asessDescrWrap .quantity {
  overflow: hidden;
}

.asessDescrWrap .quantity .quantity-nav .quantity-button {
  top: 0;
  height: 100%;
  width: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.35s ease;
  color: #cccccc;
}

.asessDescrWrap .quantity .quantity-nav .quantity-button.quantity-plus {
  right: 0;
}

.asessDescrWrap .quantity .quantity-nav .quantity-button.quantity-minus {
  left: 0;
}

.asessDescrWrap .quantity .quantity-nav .quantity-button:hover {
  color: var(--primary-color);
}

.asessDescrWrap .textWrap p strong {
  font-size: 18px;
  line-height: 32px;
  color: #212935;
}

.asessDescrWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.assesTextDescr {
  display: flex;
  align-items: center;
}

.asessDescrWrap .icnAsses {
  margin-right: 15px;
}

.asseriesDetails > li {
  border-bottom: 1px solid #eaebec;
  padding-bottom: 27px;
  margin-bottom: 25px;
}

.assesTextDescr .textWrap {
  font-size: 13px;
}

.assesTextDescr .textWrap p {
  margin-bottom: 10px;
}

.formConfirmText {
  font-size: 16px;
  margin-bottom: 24px;
  display: block;
}

.formConfirmText a {
  text-decoration: underline;
  font-weight: 500;
  color: var(--primary-color);
}

.txtList {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  padding-left: 10px;
  margin-bottom: 24px;
  margin-top: -8px;
}

.txtList li {
  position: relative;
  padding-left: 12px;
}

.txtList li:before {
  position: absolute;
  content: "";
  left: 0;
  top: 11px;
  background-color: #6e737a;
  width: 4px;
  height: 4px;
  border-radius: 50%;
}

.checkBoxWrap {
  display: flex;
  align-items: center;
}

.checkBoxWrap #terms_and_conditions {
  position: fixed;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
}

.checkBoxWrap .formConfirmText {
  position: relative;
  margin-bottom: 0;
  padding-left: 40px;
}

.checkBoxWrap .formConfirmText:before,
.checkBoxWrap .formConfirmText:after {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.checkBoxWrap .formConfirmText:before {
  width: 24px;
  height: 24px;
  border: 2px solid var(--primary-color);
  border-radius: 4px;
  transition: all 0.35s ease;
}

.checkBoxWrap .formConfirmText:after {
  left: 6px;
  font-size: 15px;
  content: "\f00c";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
  opacity: 0;
  visibility: hidden;
  color: #fff;
}

.checkBoxWrap input[type="checkbox"]:checked + .formConfirmText:before {
  background-color: var(--primary-color);
}

.checkBoxWrap input[type="checkbox"]:checked + .formConfirmText:after {
  opacity: 1;
  visibility: visible;
}

.appartSpecsWrap {
  background-color: #f6f6f6;
  border-radius: 16px;
}

/* Add this CSS in your stylesheets or as a style tag in your component */
.stickycard {
  position: sticky;
  top: 35px;
}

.appartSpecsWrap .imgHolder img {
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  width: 100%;
}

.appartHeader {
  margin-bottom: 16px;
}

.reservationSection .appartHeader h4 {
  font-size: 16px;
  color: #212935;
  font-weight: 500;
}

.appartHeader h3,
.appartHeader .h3 {
  font-size: 19px;
  color: #212935;
  font-weight: 500;
}

.dateTimeInfo {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 24px;
}

.dateTimeInfo > li h4,
.dateTimeInfo > li .h4 {
  font-size: 14px;
  font-weight: 500;
  color: #212935;
}

.dateTimeInfo > li {
  width: 50%;
  border: 1px solid #cccccc;
  padding: 16px;
  border-radius: 8px;
}

.dateTimeInfo > li:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: 0;
}

.dateTimeInfo > li:nth-child(2) {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
}

.dateTimeInfo > li.icnWrapList {
  width: 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icnDescrWrap {
  display: flex;
}

.icnDescrWrap .icnAsses {
  margin-right: 15px;
  display: block;
}

.totalRow {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 20px;
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 18px;
  color: #212935;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.appartQunatList {
  margin-bottom: 28px;
}

.appartQunatList > li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.appartQunatList > li .appartPrice {
  font-size: 18px;
  color: #212935;
  font-weight: 500;
}

.appartDescrTextWrap {
  padding: 24px;
}

.appartDescrTextWrap .submitBtn .btnPrimary {
  width: 100%;
  text-align: center;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.appartDescrTextWrap .submitBtn .btnPrimary:hover {
  background-color: #fff;
  /* color: #fff; */
}

.quantity {
  position: relative;
}

.quantity .quantity-nav .quantity-plus {
  position: absolute;
  top: 9px;
  right: 17px;
  cursor: pointer;
}

.quantity .quantity-nav .quantity-minus {
  position: absolute;
  top: 9px;
  left: 17px;
  cursor: pointer;
}

.quantity input::-webkit-outer-spin-button,
.quantity input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.quantity input[type="number"] {
  -moz-appearance: textfield;
}

.quantity input:focus,
.quantity input:focus-visible {
  outline: 0;
}

.mapStylesBlocks .appartmentListWrap .chCol8 {
  width: 100%;
  transition: all 0.35s ease;
}

.mapStylesBlocks .appartmentListWrap .chCol4 {
  width: 100%;
  transition: all 0.35s ease;
}

.appartmentListWrap .chCol4 {
  width: 100%;
}

.mapStylesBlocks .appartmentListWrap .blocksStructMain .chCol4 {
  width: 100%;
  transition: all 0.35s ease;
}

.mapStylesBlocks .appartmentListWrap .blocksStructMain .appartInfoWrap {
  cursor: pointer;
  transition: all 0.35s ease;
  border-radius: 8px;
}

.mapStylesBlocks
  .appartmentListWrap
  .blocksStructMain
  .appartInfoWrap.selected {
  border: 2px solid #6562ac;
}

.blocksStructMain {
  display: flex;
  flex-wrap: wrap;
}
.appartInfoWrap {
  cursor: pointer;
  transition: all 0.35s ease;
  border-radius: 8px;
  min-height: 610px;
  /* height: 100vh; */
}

/* .mapStylesBlocks
  .appartmentListWrap
  .blocksStructMain
  .appartInfoWrap
  .appatTextWrap
  .featureIcnList
  li:nth-last-child(-n + 4) {
  display: none;
} */

.mapStylesBlocks
  .appartmentListWrap
  .blocksStructMain
  .appartInfoWrap
  .appatTextWrap
  h2,
.mapStylesBlocks
  .appartmentListWrap
  .blocksStructMain
  .appartInfoWrap
  .appatTextWrap,
.h2 {
  font-size: 18px;
  margin-bottom: 4px;
  line-height: 24px;
  transition: all 0.35s ease;
}

.mapStylesBlocks
  .appartmentListWrap
  .blocksStructMain
  .appartInfoWrap
  .appatTextWrap,
.customize-tooltip p {
  font-size: 14px !important;
  transition: all 0.35s ease;
}

.mapStylesBlocks
  .appartmentListWrap
  .blocksStructMain
  .appartInfoWrap
  .appatTextWrap
  p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.mapStylesBlocks
  .appartmentListWrap
  .blocksStructMain
  .appartInfoWrap
  .appatTextWrap {
  padding: 16px;
}

.mapStylesBlocks
  .appartmentListWrap
  .blocksStructMain
  .appartInfoWrap
  .appatTextWrap
  strong {
  display: none;
  transition: all 0.35s ease;
}

.mapStylesBlocks
  .appartmentListWrap
  .blocksStructMain
  .appartInfoWrap
  .appartImageHolder
  img {
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.mapStylesBlocks .blocksStructMain {
  max-height: 756px;
  height: 100%;
  overflow-y: scroll;
}

.mapStylesBlocks .blocksStructMain::-webkit-scrollbar {
  width: 10px;
}

.mapStylesBlocks .blocksStructMain::-webkit-scrollbar-track {
  background: transparent;
}

.mapStylesBlocks .blocksStructMain::-webkit-scrollbar-thumb {
  background: #eaebec;
  border-radius: 8px;
}

.mapStylesBlocks
  .blocksStructMain
  .mapStylesBlocks
  .blocksStructMain
  .mapStylesBlocks
  .pagiNation {
  display: none;
}

.mapImagewrap {
  position: relative;
  max-height: 756px;
  height: 100%;
  display: none;
}

.mapStylesBlocks .mapImagewrap {
  display: block;
}

.mapImagewrap .appatTextWrap {
  max-width: 403px;
  background-color: #f6f6f6;
  border-radius: 16px;
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.35s ease;
  filter: drop-shadow(0px 58px 40px rgba(33, 41, 53, 0.07))
    drop-shadow(0px 22.3407px 12.7407px rgba(33, 41, 53, 0.0425185))
    drop-shadow(0px 4.72593px 3.25926px rgba(33, 41, 53, 0.0274815));
}
.customize-tooltip {
  max-width: 403px;
  border-radius: 16px;
  transition: all 0.35s ease;
}

.mapImagewrap:hover .appatTextWrap {
  top: 60px;
  opacity: 1;
  visibility: visible;
}

.mapImagewrap img {
  width: 100%;
  height: 100%;
}

.calenderWrap {
  position: relative;
}

.calenderWrap .dropdownArrow,
.dropdownWrap .dropdownArrow {
  position: absolute;
  right: 16px;
  top: 26px;
  transition: all 0.35s ease;
  pointer-events: none;
}

.calenderWrap.datepicker-active
  .dropdownArrow
  .dropdownWrap.dropdown-active
  .dropdownArrow {
  transform: translateY(-50%) rotate(-180deg);
}

.calenderWrap.datepicker-active .formControl,
.dropdownWrap.dropdown-active .dropdownButton {
  border-color: #6562ac;
}

.hasDatepicker,
.dropdownButton {
  cursor: pointer;
  transition: all 0.35s ease;
}

.hasDatepicker:hover,
.dropdownWrap .dropdownButton:hover {
  border-color: var(--primary-color);
}

.hasDatepicker:focus,
.hasDatepicker:focus-visible {
  border-color: #6562ac;
}

/* .formGroup .rental-dp-calender {
  position: absolute;
  max-width: 928px !important;
  background: #ffffff;
  border-radius: 24px;
  border: 0 !important;
  padding: 44px 35px 50px 31px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  left: 0 !important;
  top: calc(100% + 48px) !important;
  transition: all 0.35s ease;
  font-family: "Poppins", sans-serif;
  text-align: center;
} */
/* 
.rental-dp-calender .ui-datepicker-group {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  float: none;
}

.rental-dp-calender
  .ui-datepicker-group.ui-datepicker-group-last
  .ui-datepicker-calendar {
  margin-right: 0 !important;
  margin-left: auto !important;
}

.rental-dp-calender
  .ui-datepicker-group.ui-datepicker-group-last
  .ui-datepicker-calendar::before {
  position: absolute;
  content: "";
  left: -36px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #eaebec;
  width: 2px;
  height: 72px;
}

.rental-dp-calender .ui-datepicker-header {
  background: transparent;
  border: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #6e737a;
  padding: 0;
  margin-bottom: 42px;
}

.rental-dp-calender .ui-datepicker-header .ui-datepicker-prev,
.rental-dp-calender .ui-datepicker-header .ui-datepicker-next {
  border: 1px solid #eaebec;
  border-radius: 8px;
  width: 48px;
  height: 48px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 0.35s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #cccccc;
  font-size: 15px;
}

.rental-dp-calender .ui-datepicker-header .ui-datepicker-prev .ui-icon,
.rental-dp-calender .ui-datepicker-header .ui-datepicker-next .ui-icon {
  display: none;
}

.rental-dp-calender .ui-datepicker-header .ui-datepicker-prev:before,
.rental-dp-calender .ui-datepicker-header .ui-datepicker-next:before {
  position: absolute;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}

.rental-dp-calender .ui-datepicker-header .ui-datepicker-prev:before {
  content: "\f104";
}

.rental-dp-calender .ui-datepicker-header .ui-datepicker-next:before {
  content: "\f105";
}

.rental-dp-calender
  .ui-datepicker-header
  .ui-datepicker-prev.ui-state-hover:before,
.rental-dp-calender
  .ui-datepicker-header
  .ui-datepicker-next.ui-state-hover:before {
  color: #212935;
}

.rental-dp-calender .ui-datepicker-calendar th {
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  color: #6562ac;
  border-bottom: 1px solid #eaebec;
}

.rental-dp-calender .ui-datepicker-calendar {
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  color: #6e737a;
  text-align: center;
  margin: 0 !important;
  position: relative;
  width: 92%;
}

.rental-dp-calender .ui-datepicker-calendar tbody td a,
.rental-dp-calender
  .ui-datepicker-calendar
  tbody
  td.ui-state-disabled
  span.ui-state-default {
  text-align: center;
  background: transparent !important;
  border: 0 !important;
  transition: all 0.35s ease;
  border-radius: 8px;
}

.rental-dp-calender
  .ui-datepicker-calendar
  tbody
  td.ui-state-disabled
  span.ui-state-default {
  color: #cccccc;
}

.rental-dp-calender .ui-datepicker-calendar tbody td.ui-datepicker-today a {
  background: #ededed !important;
}

.rental-dp-calender .ui-datepicker-calendar tbody td a.ui-state-hover {
  background: #ededed !important;
}

.rental-dp-calender .ui-datepicker-calendar tbody td a.ui-state-active {
  background-color: #6562ac !important;
}

.rental-dp-calender .ui-datepicker-calendar tbody tr:first-child td {
  padding-top: 16px;
} */

#pageWrapper:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 41, 53, 0.92);
  transition: all 0.5s ease-in-out;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
}

body.datepicker-open #pageWrapper:before,
body.dropdown-open #pageWrapper:before {
  opacity: 1;
  visibility: visible;
  z-index: 2;
}

body.datepicker-open .tpSearchBar,
body.dropdown-open .tpSearchBar {
  position: relative;
  z-index: 2;
}

body.datepicker-open .tabIcnList > li > .tabs span,
body.dropdown-open .tabIcnList > li > .tabs span {
  z-index: 0;
}

.formGroup.dropdownWrap {
  position: relative;
}

.formGroup .dropdownButton {
  width: 100%;
  background-color: #f6f6f6;
  border: 1px solid #f6f6f6;
  padding: 16px 24px;
  border-radius: 16px;
  font-weight: 500;
  color: #212935;
  font-size: 14px;
  display: block;
}

.customDropdown {
  padding: 44px 48px 40px;
  max-width: 460px;
  /* width: 100%; */
  width: calc(100% + 262px);
  background: #ffffff;
  border-radius: 24px;
  position: absolute;
  top: calc(100% + 170px);
  right: 0;
  /*	top: calc(100% + 80px);
	right: 0;*/
  opacity: 0;
  visibility: hidden;
}

.dropdownWrap.dropdown-active .customDropdown {
  opacity: 1;
  visibility: visible;
}

.numberOfPerson .formRow {
  display: flex;
  justify-content: space-between;
}

.numberOfPerson .formRow:last-child {
  margin-bottom: 10px;
}

.numberOfPerson .formRow + .formRow {
  padding-top: 24px;
  margin-top: 24px;
  border-top: 1px solid #eaebec;
}

.numberOfPerson .labelWrap {
  padding-right: 20px;
}

.numberOfPerson .labelWrap span {
  display: block;
  width: 100%;
}

.numberOfPerson .labelWrap span.label {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #212935;
  margin-bottom: 5px;
}

.numberOfPerson .labelWrap span.description {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #6e737a;
}

.numberOfPerson .inputWrap {
  flex-shrink: 0;
  padding-top: 11px;
}

.numberOfPerson .inputWrap .quantity,
.numberOfPerson .inputWrap .formControl {
  max-width: 152px;
  width: 100%;
}

.numberOfPerson .inputWrap .formControl {
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 12px;
  font-size: 16px;
  color: #212935;
}

.numberOfPerson .inputWrap .formControl:focus,
.numberOfPerson .inputWrap .formControl:focus-visible {
  outline: none;
}

.numberOfPerson .inputWrap .quantity input {
  width: inherit;
  text-align: center;
  padding-left: 40px;
  padding-right: 40px;
  border: 0;
}

.numberOfPerson .quantity .quantity-button {
  border: 1px solid #eaebec;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  transition: all 0.35s ease;
  top: 50%;
  transform: translateY(-50%);
  color: var(--primary-color);
  font-size: 14px;
}

.numberOfPerson .quantity .quantity-button.quantity-plus {
  right: 0;
}

.numberOfPerson .quantity .quantity-button.quantity-minus {
  left: 0;
}

.numberOfPerson .quantity .quantity-button.quantity-minus .fa-minus:before {
  content: "\f104";
}

.numberOfPerson .quantity .quantity-button.quantity-plus .fa-plus:before {
  content: "\f105";
}

.numberOfPerson .quantity .quantity-button:hover {
  background-color: #eaebec;
  color: #000;
}

.resultWrap .resultTitle {
  background-color: var(--secondary-color);
  border-radius: 16px;
  height: 56px;
  display: flex;
  align-items: center;
  padding-right: 18px;
  padding-left: 18px;
  color: #212935;
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  pointer-events: none;
}

.resultWrap .resultCountText {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.resultWrap .resultCountNumber {
  color: var(--primary-color);
  font-size: 16px;
  line-height: 24px;
  margin-right: 7px;
}

.appartHeadingHead a {
  color: #212935;
}

.appartHeadingHead a:hover {
  color: #6562ac;
}

.mapStylesBlocks
  .blocksStructMain
  .appartInfoWrap
  .appatTextWrap
  .featureIcnList {
  margin-left: -5px;
  margin-right: -5px;
}

.mapStylesBlocks
  .blocksStructMain
  .appartInfoWrap
  .appatTextWrap
  .featureIcnList
  > li {
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 5px;
}

.mapStylesBlocks
  .blocksStructMain
  .appartInfoWrap
  .appatTextWrap
  .priceBtnWrap
  .priceTag {
  font-size: 14px;
}

.mapStylesBlocks
  .blocksStructMain
  .appartInfoWrap
  .appatTextWrap
  .priceBtnWrap
  .priceTag
  b {
  font-size: 22px;
}

.mapStylesBlocks
  .blocksStructMain
  .appartInfoWrap
  .appatTextWrap
  .priceBtnWrap {
  justify-content: flex-end;
  margin-top: 14px;
}
/* .customize-tooltip .priceBtnWrap {
  justify-content: flex-end;
  margin-top: 14px;
} */
.customize-tooltip .btnPrimary {
  padding: 6px 10px;
  font-size: 11px;
  text-align: center;
  min-width: 81px;
  /* display: none; */
}

.mapStylesBlocks
  .blocksStructMain
  .appartInfoWrap
  .appatTextWrap
  .priceBtnWrap
  .btnPrimary {
  padding: 6px 10px;
  font-size: 11px;
  text-align: center;
  min-width: 81px;
  display: none;
}

.searchInputWrap {
  margin-bottom: 16px;
  position: relative;
}

.searchInputWrap .fromControl {
  height: 55px;
  padding: 15px 48px 15px 20px;
  background-color: #ffffff;
  border-radius: 12px;
  border: 1px solid #ffffff;
  display: block;
  width: 100%;
}

.searchInputWrap .icn {
  position: absolute;
  width: 25px;
  height: 25px;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.appartCountWrap .homeIcn,
.appartCountWrap strong,
.catIndiWrap {
  position: none;
  cursor: default;
}

.tpSearchBar input[type="search"] {
  box-sizing: border-box;
  transition: all 0.35s ease;
}

.searchBarDesktpoWrap {
  /*display: none;*/
  height: 0;
  overflow: hidden;
}

.tpSearchBar .formGroup .inputWrap {
  position: relative;
}

.tpSearchBar .formGroup .inputWrap .icn {
  position: absolute;
  top: 57%;
  left: 20px;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.35s ease;
}

.tpSearchBar input[type="search"] {
  padding-right: 45px;
}

.tpSearchBar input[type="search"]::-webkit-search-cancel-button,
.tpSearchBar input[type="search"]::-webkit-search-decoration {
  background-image: url("images/icnClose.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 24px;
  transition: all 0.35s ease;
  position: absolute;
  right: 10px;
}

.tpSearchBar input[type="search"]:focus-visible::-webkit-search-cancel-button {
  opacity: 1;
  visibility: visible;
  display: block;
}

.tpSearchBar input[type="search"]:focus-visible {
  padding-left: 54px;
  transition: all 0.35s ease;
  border-color: var(--primary-color);
  outline: 0;
  box-shadow: none;
}

.tpSearchBar input[type="search"]:focus-visible + .icn {
  opacity: 1;
  visibility: visible;
}

.cityResultList {
  padding-top: 32px;
  padding-bottom: 25px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212935;
}

.cityResultList li {
  display: flex;
  align-items: center;
}

.cityResultList li > a {
  display: flex;
  align-items: center;
  color: inherit;
  width: 100%;
  transition: all 0.35s ease;
  padding: 12px 10px;
  border-radius: 12px;
}

.cityResultList li > a:hover {
  color: var(--primary-color);
  background-color: var(--secondary-color);
}

.cityResultList li + li {
  margin-top: 2px;
}

.cityResultList li .icnPin {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  border: 1px solid #eaebec;
  margin-right: 16px;
}

.featureIcnListOpener {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  background: #6562ac;
  border-radius: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 20px;
  cursor: pointer;
  transition: all 0.35s ease;
}

.mapStylesBlocks .appartInfoWrap.featListActive .featureIcnListOpener,
.featureIcnListOpener:hover {
  color: #6562ac;
  background: #fff;
}

.mapStylesBlocks .featureIcnListOpener {
  opacity: 1;
  visibility: visible;
  position: relative;
  float: right;
  top: -25px;
}

.mapStylesBlocks .appartInfoWrap .featureIcnList {
  /* max-height: 0; */
  /* overflow: hidden; */
  transition: max-height 0.35s linear;
}

.mapStylesBlocks .appartInfoWrap.featListActive .featureIcnList {
  overflow: visible;
  max-height: 150px;
}

.mapImagewrap .mapWrap {
  border-radius: 24px;
  height: inherit;
}

.mapImagewrap .mapWrap img {
  border-radius: inherit;
}

.moreResultTxt {
  display: none;
}

@media (max-width: 991px) {
  .mapStylesBlocks .appartmentListWrap {
    min-width: calc(100% + 30px);
    width: 100%;
    margin-left: -15px;
    padding: 0;
  }

  .mapStylesBlocks .appartmentListWrap .blocksStructMain {
    padding: 24px 20px 0;
    background-color: #fff;
    margin-top: -40px;
    border-radius: 24px;
    position: relative;
    z-index: 1;
    margin-left: 0;
    margin-right: 0;
  }

  .mapStylesBlocks .appartmentListWrap .blocksStructMain:before {
    position: absolute;
    content: "";
    left: 50%;
    transform: translateX(-50%);
    top: 16px;
    width: 48px;
    height: 4px;
    border-radius: 50px;
    background-color: #eaebec;
  }

  .mapStylesBlocks .appartmentListWrap .moreResultTxt {
    display: block;
    width: 100%;
    color: #474d57;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    margin-bottom: 16px;
    text-align: center;
  }

  .chCol100 {
    width: 100%;
  }

  .pagiNation {
    margin-bottom: 50px;
  }

  .mapImagewrap .mapWrap {
    border-radius: 0;
  }

  .mapStylesBlocks .tabIcnList {
    /* position: fixed;
    right: 16px;
    bottom: 25px;
    box-shadow: 0px 4px 24px rgba(33, 41, 53, 0.15); */
  }
}

@media (max-width: 767px) {
  .mapStylesBlocks .appartmentListWrap {
    /*		padding-left: 10px;
		padding-right: 10px;*/
  }

  .mapImagewrap .appatTextWrap h2,
  .customize-tooltip .h2,
  .mapImagewrap .appatTextWrap h2 {
    font-size: 13px;
    margin-bottom: 4px;
  }

  .customize-tooltip p,
  .mapImagewrap .appatTextWrap p {
    font-size: 11px;
    margin-bottom: 4px;
  }

  .mapImagewrap .appatTextWrap strong,
  .customize-tooltip strong {
    font-size: 12px;
  }
  .customize-tooltip .featureIcnList > li,
  .mapImagewrap .featureIcnList > li {
    margin-right: 4px;
    margin-bottom: 1px;
  }
  .customize-tooltip .priceBtnWrap .priceTag,
  .mapImagewrap .priceBtnWrap .priceTag {
    font-size: 11px;
    margin-bottom: 5px;
  }

  .mapImagewrap .priceBtnWrap .priceTag b {
    font-size: 14px;
  }

  .formGroup small.error-text {
    position: relative;
    bottom: auto;
    display: block;
    width: 100%;
  }

  .catListWrap {
    padding-bottom: 8px;
    margin-bottom: 16px;
  }

  .formPageMainSec,
  .reservationSection {
    padding-top: 100px;
  }
}

@media (max-width: 575px) {
  .appartInfoWrap .btnPrimary {
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.01em;
    font-weight: 500;
    border-radius: 8px;
    padding: 8px;
    text-align: center;
    min-width: unset;
  }

  .formPageMainSec,
  .reservationSection {
    padding-top: 40px;
  }

  /*	.filterOpener {
		background-color: #fff;
	    padding: 10px 12px;
	    border-radius: 8px;
	    transition: all .35s ease;
	    color: #6E737A;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    font-size: 22px;
	    width: fit-content;
	    cursor: pointer;
	}

	.filterOpener:hover {
		color: #212935;
	}

	.filterOpener.active {
		color: #fff;
		background-color: #6562AC;
	}*/
  .languageForm.desktop {
    display: none;
  }

  .searchBarMobileWrap {
    position: fixed !important;
    top: 148px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    border-radius: 24px 24px 0 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.35s ease;
    background-color: #f7f7f7;
    /*height: 100%;
		overflow-y: auto;
		min-height: 100vh;
		padding: 16px 32px 40px;*/
  }

  body.filter-open {
    overflow: hidden;
    background-color: #f7f7f7;
  }

  body.filter-open .searchBarMobileWrap {
    top: 24px;
    opacity: 1;
    visibility: visible;
    height: 100%;
    min-height: 100vh;
  }

  .filterCloser i {
    font-size: 12px;
    margin-right: 13px;
    font-weight: 900;
  }

  .filterCloser {
    background-color: #fafafa;
    box-shadow: 0px 4px 24px rgba(33, 41, 53, 0.15);
    border-radius: 12px;
    padding: 14px 10px;
    text-align: center;
    color: #212935;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 24px;
    width: 81px;
    cursor: pointer;
    transition: all 0.35s ease;
  }

  .filterCloser:hover {
    color: #fff;
    background-color: #6562ac;
  }

  #pageWrapper:before {
    content: none !important;
  }

  .cityLable {
    position: relative;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    color: #212935;
    opacity: 0;
    visibility: hidden;
    max-height: 0;
    margin: 0;
    transition: max-height 0.5s linear, margin 0.35s linear, opacity 0.35s ease,
      visibility 0.35s ease;
  }

  .icnClose {
    position: absolute;
    top: 4px;
    right: 10px;
    width: 24px;
    height: 24px;
    display: flex;
    color: #212935;
    font-size: 14px;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    transition: all 0.35s ease;
    border: 1px solid #eaebec;
  }

  .cityInputWrap.dropdown-active .cityLable {
    margin-top: 16px;
    margin-bottom: 16px;
    max-height: 40px;
    opacity: 1;
    visibility: visible;
  }

  .customDropdown.cityDropdown {
    position: relative;
    padding: 0;
    max-height: 0;
    width: 100%;
    max-width: 100%;
    background-color: transparent;
    transition: max-height 0.5s linear;
  }

  .cityInputWrap.dropdown-active .customDropdown.cityDropdown {
    opacity: 1;
    visibility: visible;
    max-height: 450px;
  }

  .formGroup .dropdownButton {
    color: #6e737a;
    font-weight: 500;
  }

  .tpSearchBar {
    background-color: transparent;
    padding: 0;
    border-radius: 0;
    margin-bottom: 0;
    height: 100%;
  }

  .tpSearchBar .formBody {
    background-color: #fff;
    padding: 32px 18px 25px;
    border-radius: 24px;
    max-height: calc(100% - 155px);
    /*max-height: 530px;*/
    overflow: hidden;
  }

  .innerBodyWrap {
    max-height: 450px;
    overflow-y: auto;
  }

  .formFooter {
    background-color: #ffffff;
    border-radius: 24px 24px 0 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }

  .formFooter .btnWrap {
    padding: 20px 24px;
  }

  .btnSubmit {
    background-color: #6562ac;
    border: 1px solid #6562ac;
    width: 100%;
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    padding: 12px 24px;
    border-radius: 8px;
  }

  button[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }

  .inputBtnWrap {
    padding: 20px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .inputBtnWrap.resultWrap .resultTitle {
    flex-shrink: 0;
    flex-grow: 1;
    margin-right: 16px;
    height: 50px;
  }

  .resultWrap .btnPrimary {
    background-color: #6562ac;
    border: 1px solid #6562ac;
    padding: 11px 24px 12px;
  }

  .numberOfPerson {
    max-width: 100%;
    width: 100%;
    position: relative;
    top: auto;
    right: auto;
    max-height: 0;
    min-height: 0;
    height: 0;
    padding: 0;
    transition: all 0.35s ease;
  }

  .numberOfPerson .formRow + .formRow {
    padding: 0;
    margin: 0;
    border: 0;
    overflow: hidden;
    max-height: 0;
  }

  .dropdownWrap.dropdown-active .customDropdown.numberOfPerson {
    padding: 29px 10px 40px;
    max-height: 400px;
    height: 100%;
  }

  .dropdownWrap.dropdown-active .numberOfPerson .formRow + .formRow {
    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid #eaebec;
    transition: all 0.35s ease;
    overflow: visible;
    max-height: 100px;
  }

  .dropdownWrap.dropdown-active .numberOfPerson .formRow:last-child {
    padding-bottom: 44px;
  }

  .dropdownWrap.dropdown-active .blockLable {
    /*margin-top: 16px;*/
    margin-bottom: 20px;
    max-height: 40px;
    opacity: 1;
    visibility: visible;
  }

  /*.dropdownWrap.dropdown-active .dropdownButton,*/
  .dropdownWrap.dropdown-active .dropdownArrow {
    display: none;
  }

  .dropdownWrap.dropdown-active .dropdownButton {
    transition: all 0.35s ease;
  }

  .dropdownWrap.dropdown-active .dropdownButton {
    max-height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
    margin: 0;
    opacity: 0;
    visibility: hidden;
  }

  .blockLable {
    position: relative;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    color: #212935;
    opacity: 0;
    visibility: hidden;
    max-height: 0;
    margin: 0;
    transition: max-height 0.5s linear, margin 0.35s linear, opacity 0.35s ease,
      visibility 0.35s ease;
  }

  .icnClosed {
    position: absolute;
    top: 4px;
    right: 10px;
    width: 24px;
    height: 24px;
    display: flex;
    color: #212935;
    font-size: 14px;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    transition: all 0.35s ease;
    border: 1px solid #eaebec;
  }

  .rental-dp-calender {
    position: relative !important;
    display: block;
  }

  .innerBodyWrap div.formGroup:not(.calenderWrap) {
    max-height: 500px;
    transition: all 0.35s ease;
  }

  body.datepicker-open .innerBodyWrap div.formGroup:not(.calenderWrap) {
    max-height: 0;
    padding: 0;
    margin: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.35s ease;
    /*transition: max-height .35s linear, margin .35s linear, opacity .35s ease, visibility .35s ease;*/
  }

  .mapImagewrap .appatTextWrap {
    top: auto !important;
    bottom: 35px;
    background-color: #fff;
  }

  .mapImagewrap:hover .appatTextWrap {
    bottom: 50px;
  }

  .mapImagewrap .mapWrap {
    min-height: 500px;
  }

  .mapImagewrap .appatTextWrap .btnPrimary:hover {
    background-color: #f6f6f6;
  }

  .mapStylesBlocks .appartmentListWrap .blocksStructMain {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 370px) {
  .inputBtnWrap {
    flex-direction: column;
  }
  .assesTextDescr {
    max-width: 150px;
    flex-direction: inherit;
  }
  .asessDescrWrap {
    flex-wrap: nowrap;
  }

  .inputBtnWrap .resultTitle {
    width: 100%;
    margin-right: 0 !important;
    margin-bottom: 15px;
  }

  .resultWrap .btnPrimary {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .customDropdown.cityDropdown {
    width: calc(100% + 200px);
    padding: 30px 18px 30px;
    left: 0;
    right: auto;
    max-height: 480px;
    overflow-y: auto;
  }

  .cityResultList {
    padding-top: 0;
    padding-bottom: 0;
  }

  .tpSearchBar {
    padding: 32px;
  }

  .priceBtnWrap .priceTag {
    font-size: 18px;
  }

  .priceBtnWrap .priceTag b {
    font-size: 24px;
  }

  .appartmentListWrap {
    padding: 32px 33px;
  }

  .tpSearchBar .chCol2 {
    width: 50%;
  }

  .appatTextWrap h2,
  .appatTextWrap .h2 {
    font-size: 23px;
  }

  .appatTextWrap strong {
    font-size: 18px;
  }

  .appatTextWrap {
    font-size: 16px;
  }

  .priceBtnWrap {
    justify-content: space-between;
  }

  .priceBtnWrap .priceTag {
    margin-bottom: 0;
  }

  .pagiNation > li + li {
    margin-left: 24px;
  }

  .appartBookingDetailWrap {
    padding: 96px 40px;
  }

  .headingHead h2,
  .headingHead .h2 {
    font-size: 32px;
  }

  .appartHeader h3,
  .appartHeader .h3 {
    font-size: 24px;
  }

  .reservationSection .appartHeader h4 {
    font-size: 18px;
  }

  .bookingForm .chCol6 {
    width: 50%;
  }

  .asessDescrWrap {
    justify-content: space-between;
  }

  .asessDescrWrap .icnAsses {
    margin-right: 42px;
  }

  .assesTextDescr .textWrap {
    font-size: 16px;
  }

  .assesTextDescr .textWrap p {
    margin-bottom: 0;
  }

  .searchBarMobileWrap,
  .filterOpener {
    display: none !important;
  }

  .searchBarDesktpoWrap {
    /*display: block !important;*/
    height: auto;
    overflow: visible;
  }

  .tpSearchBar input[type="search"]:focus-visible {
    padding-left: 40px;
  }

  .tpSearchBar .formGroup .inputWrap .icn {
    left: 12px;
    width: 20px;
  }

  .tpSearchBar .formGroup .inputWrap .icn img {
    max-width: 100%;
    height: auto;
  }

  .searchInputWrap {
    display: none;
  }

  .customDropdown {
    right: -32px;
  }

  .customDropdown.cityDropdown {
    top: calc(100% + 242px);
    left: -31px;
  }

  .mapStylesBlocks .appartmentListWrap .blocksStructMain .appartInfoWrap {
    display: flex;
    /* align-items: center; */
  }
}

@media (min-width: 768px) {
  .customDropdown {
    top: calc(100% + 80px);
    right: auto;
    left: -31px;
  }

  .customDropdown.cityDropdown {
    top: calc(100% + 162px);
  }

  .rental-dp-calender {
    padding: 44px 35px 50px 31px;
  }

  .rental-dp-calender .ui-datepicker-calendar {
    font-size: 18px;
    line-height: 32px;
  }

  .tpSearchBar .chCol2 {
    width: 33.333%;
  }

  .blocksStructMain .chCol4 {
    width: 50%;
  }

  .catAppartList {
    margin-left: 32px;
  }

  .selectTabList {
    margin-left: 32px;
  }

  .tabIcnList {
    margin-left: 32px;
  }

  .tpSearchBar .colsHolder .chCol2:nth-child(5) {
    margin-left: 70px;
  }

  .tpSearchBar .colsHolder .chCol2:nth-child(4) {
    position: relative;
    /*border-right: 2px solid #EAEBEC; */
  }

  .tpSearchBar .colsHolder .chCol2:nth-child(4):before {
    position: absolute;
    content: "";
    right: -25px;
    top: 0;
    height: 100%;
    background-color: #eaebec;
    width: 2px;
  }

  .tpSearchBar .colsHolder .chCol2:nth-child(6) {
    width: 10%;
  }
}

@media (min-width: 992px) {
  /*	.tpSearchBar .chCol2 {
		width: 16.666%;
	}*/

  .tpSearchBar .formGroup,
  .tpSearchBar .searchBarBtnWrap,
  .tpSearchBar .submitBtn {
    margin-bottom: 0;
  }

  .priceBtnWrap .btnPrimary {
    padding: 12px 10px;
  }

  .blocksStructMain .chCol4 {
    width: 33.333%;
  }

  .catAppartList > li {
    margin-bottom: 0;
  }

  .tabIcnList {
    margin-left: 0;
  }

  .appartBookingDetailWrap .chCol8 {
    width: 60%;
  }

  .appartBookingDetailWrap .chCol4 {
    width: 40%;
  }

  .rowReverse {
    flex-direction: row-reverse;
  }

  .mapStylesBlocks .appartmentListWrap .chCol8 {
    width: 60%;
  }

  .mapStylesBlocks .appartmentListWrap .chCol4 {
    width: 40%;
  }
}

@media (min-width: 1200px) {
  .mapStylesBlocks .appartmentListWrap .chCol8 {
    width: 65%;
  }

  .mapStylesBlocks .appartmentListWrap .chCol4 {
    width: 35%;
  }

  .tpSearchBar .chCol2 {
    width: 16.666%;
  }

  .customDropdown {
    right: 0;
    left: auto;
  }

  .customDropdown.cityDropdown {
    top: calc(100% + 80px);
    /*left: 0;*/
  }

  .formGroup small.error-text {
    position: absolute;
    right: 0;
    bottom: -24px;
  }

  .txtList {
    padding-right: 100px;
  }

  .appatTextWrap h2,
  .appatTextWrap .h2 {
    font-size: 24px;
  }

  .priceBtnWrap .btnPrimary {
    padding: 12px 24px;
  }

  .tpSearchBar .colsHolder .chCol2:nth-child(5) {
    margin-left: 70px;
  }

  .appartBookingDetailWrap {
    padding: 96px 80px;
  }
}

@media (min-width: 1440px) {
  .tpSearchBar .colsHolder .chCol2:nth-child(5) {
    /* margin-left: 80px; */
    margin-left: 50px;
    width: 19.4%;
  }

  .appartBookingDetailWrap {
    padding: 96px 139px;
  }

  .mapStylesBlocks .featureIcnListOpener {
    top: -45px;
  }
}

.dNone {
  display: none;
}

.mb0 {
  margin-bottom: 0;
}

/* .rmdp-wrapper .rmdp-day-picker .rmdp-today {
  background-color: transparent;
}
.rmdp-wrapper .rmdp-day-picker .rmdp-today .sd {
  background-color: transparent;
  color: inherit;
  border: none;
  border: transparent;
  box-shadow: none;
} */
.leaflet-pane.leaflet-map-pane {
  z-index: 1;
}
.leaflet-top.leaflet-left {
  z-index: 1;
}

.error-message {
  color: red;
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
  display: block;
}

.react-datepicker-wrapper input {
  width: 100%;
  background-color: #f6f6f6;
  border: 1px solid #f6f6f6;
  padding: 16px 24px;
  border-radius: 16px;
  font-weight: 500;
  color: #212935;
  font-size: 14px;
}

.react-datepicker-wrapper input:focus-visible {
  border-color: var(--primary-color);
  outline: none;
}

.rental-dp-calender {
  margin-top: 30px;
}

.rental-dp-calender .react-datepicker__month-container {
  width: 100%;
}

.rental-dp-calender .react-datepicker {
  background: transparent;
  border: 0;
  box-shadow: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.rental-dp-calender .react-datepicker__header {
  background: transparent;
  border: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #6e737a;
  padding: 0;
  margin-bottom: 42px;
}

.rental-dp-calender .react-datepicker__current-month {
  color: #6e737a;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  margin-bottom: 50px;
}

.rental-dp-calender .react-datepicker__navigation {
  border: 1px solid #eaebec;
  border-radius: 8px;
  width: 48px;
  height: 48px;
  color: #cccccc;
  font-size: 15px;
}

.rental-dp-calender .react-datepicker__navigation--previous:before {
  content: "\f104";
}

.rental-dp-calender .react-datepicker__navigation--next:before {
  content: "\f105";
}

.rental-dp-calender .react-datepicker__navigation--previous,
.rental-dp-calender .react-datepicker__navigation--next {
  top: -3%;
  transform: translateY(-3%);
  cursor: pointer;
  transition: all 0.35s ease;
}

.rental-dp-calender .react-datepicker__day-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  color: #6562ac;
  border-bottom: 1px solid #eaebec;
  height: 45px;
  width: 45px;
}

.rental-dp-calender .react-datepicker__day {
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  padding-top: 10px;
  color: #6e737a;
  text-align: center;
  transition: all 0.35s ease;
  border-radius: 8px;
  height: 45px;
  width: 45px;
}

.rental-dp-calender .react-datepicker__day--today {
  background: #ededed !important;
}

.rental-dp-calender .react-datepicker__day--selected {
  background-color: #6562ac !important;
  color: #fff !important;
}

.rental-dp-calender .react-datepicker__day--disabled {
  color: #cccccc;
}

.rental-dp-calender-main .react-datepicker {
  margin-top: 30px;
}
.rental-dp-calender-main .react-datepicker {
  background: white;
  border: 0;
  box-shadow: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.rental-dp-calender-main .react-datepicker__header {
  background: transparent;
  border: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #6e737a;
  padding: 0;
  margin-bottom: 42px;
}

.rental-dp-calender-main .react-datepicker__current-month {
  color: #6e737a;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  margin-bottom: 30px;
  margin-top: 30px;
}

.rental-dp-calender-main .react-datepicker__navigation {
  border: 1px solid #eaebec;
  border-radius: 8px;
  width: 48px;
  height: 48px;
  color: #cccccc;
  font-size: 15px;
}

.rental-dp-calender-main .react-datepicker__navigation--previous:before {
  content: "\f104";
}

.rental-dp-calender-main .react-datepicker__navigation--next:before {
  content: "\f105";
}

.rental-dp-calender-main .react-datepicker__navigation--previous {
  left: 3%;
}

.rental-dp-calender-main .react-datepicker__navigation--next {
  right: 3%;
}

.rental-dp-calender-main .react-datepicker__navigation--previous,
.rental-dp-calender-main .react-datepicker__navigation--next {
  top: 3%;
  transform: translateY(3%);
  cursor: pointer;
  transition: all 0.35s ease;
}

.rental-dp-calender-main .react-datepicker__day-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  color: #6562ac;
  border-bottom: 1px solid #eaebec;
  height: 45px;
  width: 45px;
}

.rental-dp-calender-main .react-datepicker__day {
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  padding-top: 10px;
  color: #6e737a;
  text-align: center;
  transition: all 0.35s ease;
  border-radius: 8px;
  height: 45px;
  width: 45px;
}

.rental-dp-calender-main .react-datepicker__day--today {
  background-color: var(--primary-color) !important;
  color: #fff;
}

.rental-dp-calender-main .react-datepicker__day--selected {
  background-color: #6562ac;
  color: #fff;
}

.rental-dp-calender-main .react-datepicker__day--in-range {
  background-color: #eaebec;
}
.react-datepicker__day--selecting-range-start,
.react-datepicker__day--range-start,
.react-datepicker__day--selecting-range-end {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}
.rental-dp-calender-main .react-datepicker__day--disabled {
  color: #cccccc;
}
.react-datepicker__day--outside-month {
  background-color: #fff !important;
}

.rental-dp-calender-main .react-datepicker-popper {
  z-index: 9999;
}

.alert-container {
  display: flex;
  align-items: center;
  background-color: #f7f9fc; /* Light background to stand out */
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #d1d4d8;
}

.feather-icon-alert-circle {
  color: #f39c12; /* Orange color for warning */
  font-size: 24px;
  margin-right: 10px;
}

.alert-text {
  font-size: 16px;
  font-weight: 500;
  color: #333; /* Dark text for readability */
}

/* Omar - 01-oct-23 */
.react-datepicker-popper,
.margin-top-50 {
  margin-top: 40px;
}

.react-datepicker__navigation-icon {
  position: relative;
  top: 1px;
  font-size: 20px;
  width: 0;
}

.react-datepicker__navigation-icon--next::before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  left: -4px;
}

.react-datepicker__navigation-icon--previous::before {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
  right: -4px;
}

.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__month-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__quarter-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__year-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
  /* background-color: rgb(201 195 239 / 50%); */
  background-color: var(--secondary-color);
}

/* Hide element on extra-small (XS) screens */
@media (max-width: 575.98px) {
  .hidden-xs {
    display: none !important;
  }
  .visible-xs {
    display: block !important;
  }
}

/* Show element on medium-sized (MD) screens */
@media (min-width: 768px) {
  .visible-md {
    display: block !important;
  }
  .hidden-md {
    display: none !important;
  }
}
